<template>
  <section id="card-navigation" v-if="getUser.work_location_id">
    <b-row class="mb-3">

      <b-col
          md="12"
          lg="12"
      >
        <NewCalendarPlaces v-if="state_f" />

      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton,
} from 'bootstrap-vue'
import Calendar from "@/components/PlacesCalendar/Calendar";
import NewCalendarPlaces from "@/components/Appointments/NewCalendarPlaces";
import CalendarMedical from "@/components/PlacesCalendarMedical/CalendarMedical";
import {mapGetters} from "vuex";
export default {
  name: "CalendarPlace",
  data(){
    return{
      state_f:false
    }
  },
  components:{
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    Calendar,
    CalendarMedical,
    NewCalendarPlaces
  },
  computed:{
    ...mapGetters('auth', ['getUser'])
  },
  async created() {

    this.state_f = true
  }

}
</script>

<style scoped>

</style>
